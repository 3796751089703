"use client";

import React, { useEffect, useRef } from "react";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { usePathname } from "next/navigation";
import { Icons } from "./icons";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Instagram, Youtube } from "lucide-react";

const menuLinks = [
  {
    path: "/",
    label: "Home",
  },
  {
    path: "",
    label: "Produkte",
    subMenu: [
      {
        path: "/combisystems",
        label: "CombiSystems",
      },
      {
        path: "/combiplus",
        label: "CombiPlus",
      },
      {
        path: "/combiconnect",
        label: "CombiConnect",
      },
    ],
  },

  {
    path: "/ueber-uns",
    label: "Über uns",
  },
  /*
  {
    href: "/news",
    text: "CombiNews",
  }, */
  {
    path: "/combicampus",
    label: "CombiCampus",
  },
  {
    path: "/karriere",
    label: "Karriere",
  },
  {
    path: "/kontakt",
    label: "Kontakt",
  },
];
export function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const tl = useRef<any>(null);

  useGSAP(
    () => {
      gsap.set(".menu-link-item-holder", { x: -30, opacity: 0 });

      tl.current = gsap
        .timeline({ paused: true })
        .to(".cpp-overlay", {
          duration: 1,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          ease: "power4.inOut",
        })
        .to(".menu-link-item-holder", {
          x: 0,
          opacity: 1,
          duration: 0.75,
          stagger: 0.2,
          ease: "power2.out",
          delay: -0.25,
        });
    },
    { scope: container },
  );

  useEffect(() => {
    if (isMenuOpen) {
      tl.current.play();
      document.body.classList.add("overflow-hidden"); // Disable scroll
    } else {
      tl.current?.reverse();
      document.body.classList.remove("overflow-hidden"); // Enable scroll
    }

    return () => document.body.classList.remove("overflow-hidden"); // Clean up
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="" ref={container}>
      <div className="">
        {/*  <Icons.logo className="menu-logo" /> */}
        <div className="" onClick={toggleMenu}>
          <Icons.burger className="cursor-pointer transition hover:text-primary" />
        </div>
      </div>
      <div className="cpp-overlay fixed left-0 top-0 z-[50] flex h-screen w-screen bg-[#3E4342] p-8">
        <div className="fixed left-0 top-0 z-[1] flex w-screen items-center justify-between bg-[#3E4342] py-5">
          <div className="cs-container flex flex-[2] cursor-pointer items-center justify-between" onClick={toggleMenu}>
            <Icons.burgerClose className="text-white transition hover:text-primary" />
            <Icons.logo className="text-white" />
            <Icons.phone className="text-white transition hover:text-primary" />
          </div>
        </div>
        <div className="cs-container flex flex-[4] flex-col justify-between overflow-auto !p-0 lg:p-8">
          <div className="pl-[0%] pt-[15%] lg:pl-[10%]">
            {menuLinks.map((link, index) => (
              <div className="cpp-link-item group relative flex w-max items-center gap-5 sm:pl-16" key={index}>
                <Icons.mirrorLineMenu className="absolute left-0 top-[33px] hidden h-[55px] w-[55px] text-primary opacity-0 transition-opacity group-hover:opacity-100 lg:block" />
                <div className="menu-link-item-holder relative">
                  {link.path ? (
                    <Link
                      href={link.path}
                      onClick={toggleMenu}
                      className="text-[40px] font-extrabold text-white transition hover:text-primary md:text-[80px]"
                    >
                      {link.label}
                    </Link>
                  ) : (
                    <div className="cursor-default text-[40px] font-extrabold text-white transition hover:text-primary md:text-[80px]">
                      {link.label}
                    </div>
                  )}

                  {link.subMenu && (
                    <>
                      {link.subMenu.map((link, index) => (
                        <div className="cpp-link-item group flex w-max items-center gap-5 pl-10 sm:pl-20" key={index}>
                          <Icons.mirrorLineMenu className="hidden h-[24px] w-[24px] text-white opacity-100 transition-opacity group-hover:opacity-100 lg:block" />
                          <div className="menu-link-item-holder relative">
                            <Link
                              href={link.path}
                              onClick={toggleMenu}
                              className="text-2xl font-medium text-white transition hover:text-primary"
                            >
                              {link.label}
                            </Link>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="hidden items-end justify-between gap-5 md:flex md:gap-10">
            <div className="gap-5 md:flex">
              <p className="text-base font-bold text-white">CombiSystems GmbH 2024</p>

              <span>
                <Icons.mirrorLineSmall className="h-[20px] text-primary" strokeWidth={2} />
              </span>
              <Link
                href="/impressum"
                onClick={toggleMenu}
                className="flex items-center gap-2 text-white transition hover:text-primary"
              >
                Impressum
              </Link>
              <span>
                <Icons.mirrorLineSmall className="h-[20px] text-primary" strokeWidth={2} />
              </span>
              <Link
                href="/datenschutz"
                onClick={toggleMenu}
                className="flex items-center gap-2 text-white transition hover:text-primary"
              >
                Datenschutz
              </Link>
            </div>
            <div className="flex gap-3">
              <Link href="https://www.instagram.com/combisystems/" target="_blank">
                <Instagram color="#fff" className="transition hover:stroke-primary" strokeWidth={2} size={22} />
              </Link>
              <Link href="https://www.threads.net/@combiplus_kfzsvsoftware" target="_blank">
                <Icons.threads className="fill-[#fff] transition hover:fill-primary" strokeWidth={2} />
              </Link>
              <Link href="https://www.youtube.com/channel/UCZvYp2C-O9Ak1YOvihGIkRw" target="_blank">
                <Youtube color="#fff" className="transition hover:stroke-primary" />
              </Link>
            </div>
          </div>
          <div className="flex items-end justify-between gap-5 md:hidden md:gap-10">
            <div className="flex flex-col gap-5">
              <p className="text-base font-bold text-white">CombiSystems GmbH 2024</p>

              <Link href="/impressum" className="flex items-center gap-2 text-white">
                <span>
                  <Icons.mirrorLineSmall className="h-[20px] text-primary" strokeWidth={2} />
                </span>
                Impressum
              </Link>
              <Link href="/datenschutz" className="flex items-center gap-2 text-white">
                <span>
                  <Icons.mirrorLineSmall className="h-[20px] text-primary" strokeWidth={2} />
                </span>
                Datenschutz
              </Link>
            </div>
            <div className="flex items-center gap-3">
              <Link href="https://www.instagram.com/combisystems/" target="_blank">
                <Instagram color="#fff" className="transition hover:stroke-primary" strokeWidth={2} size={22} />
              </Link>
              <Link href="https://www.threads.net/@combiplus_kfzsvsoftware" target="_blank">
                <Icons.threads className="fill-[#fff] transition hover:fill-primary" color="#fff" strokeWidth={2} />
              </Link>
              <Link href="https://www.youtube.com/channel/UCZvYp2C-O9Ak1YOvihGIkRw" target="_blank">
                <Youtube color="#fff" className="transition hover:stroke-primary" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface MobileLinkProps extends LinkProps {
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
  isCurrentPage?: boolean;
}

function MobileLink({ href, onOpenChange, className, children, isCurrentPage, ...props }: MobileLinkProps) {
  const router = useRouter();
  return (
    <div className="relative">
      <Link
        href={href}
        onClick={() => {
          router.push(href.toString());
          onOpenChange?.(false); // Trigger dialog close
        }}
        className={cn(className, isCurrentPage ? "text-primary" : "text-white")}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}
